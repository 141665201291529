import React, { FC, useEffect, useState } from 'react';

import TabbedRelatedVideo from '../../../common/components/TabbedRelatedVideo';
import { ContentType } from '../../Shared/enums';

interface SameShootSameModelSimilarClipsDrawerProps {
  stockItemId: number;
  contentType: ContentType;
  onClose(): void;
}

const SameShootSameModelSimilarClipsDrawer: FC<
  SameShootSameModelSimilarClipsDrawerProps
> = ({ stockItemId, contentType, onClose }) => {
  const [arrowPosition, setArrowPosition] = useState(0);
  useEffect(() => {
    const elementToPointTo = document.querySelector(
      `[data-stock-id="${stockItemId}"]`
    );
    if (elementToPointTo) {
      const boundingRect = elementToPointTo.getBoundingClientRect();
      // Position the arrow element at the midpoint of the target element,
      // but take 24px off to account for the width of the arrow itself and the spacing of our container.
      setArrowPosition(boundingRect.left + boundingRect.width / 2 - 24);
    }
  }, [stockItemId]);

  return (
    <div className="searchDrawer w-full bg-transparent my-2.5 py-0 col-span-1 md:col-span-2 lg:col-span-3 3xl:col-span-4 4xl:col-span-5 relative">
      {/* Arrow element. */}
      {arrowPosition && (
        <div
          className="absolute w-6 h-6 bg-gray-110 transform rotate-45 -top-1.5"
          style={{ left: arrowPosition }}
        />
      )}

      <div className="bg-gray-110 rounded-lg p-6">
        <TabbedRelatedVideo
          stockItemId={stockItemId}
          contentType={contentType}
          pageSize={8}
          onClose={onClose}
        />
      </div>
    </div>
  );
};

export default SameShootSameModelSimilarClipsDrawer;
