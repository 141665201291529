import React from 'react';
import { connect } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { Close } from '@videoblocks/react-icons';
import { ButtonColor } from '@videoblocks/storywind';
import Button from '@videoblocks/storywind/components/Button';

import { setPendingPauseAudio } from '../../../Audio/actions/AudioActions';
import { selectIsLoggedIn } from '../../../auth/AuthSelectors';
import { VIDEOBLOCKS } from '../../../common/SiteConstants/SiteConstants';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import {
  StockItem,
  StockItemContext,
  StockItemFormat,
} from '../../../common/types/StockItemTypes';
import {
  getCurrentSite,
  stopRecommendedMusicDrawerTimer,
} from '../../../common/utils';
import { DrawerType } from '../SearchTypes';
import {
  collapseMoreLikeThisDrawer,
  updateDrawerNumberItemsVisible,
} from '../actions/SearchActions';
import {
  selectCollapsedSetStockItems,
  selectDrawerContentLoaded,
  selectDrawerType,
  selectSearchFeatures,
} from '../selectors/searchSelectors';
import SimpleSearchResults from './searchResults/SimpleSearchResults';

import './Drawer.less';

interface Props {
  stockItems: StockItemObj[];
  customStyles: object;
  // This is used to horizontally position the small triangle
  // that indicates which item was selected.
  indicatorClasses?: string;
  selectedItemIndicatorOffset?: number;
  drawerContentLoaded?: boolean;
  drawerType?: string;
  features: Features;
  dispatchUpdateDrawerNumberItemsVisible?: () => void;
  close: () => void;
  itemLimit?: number;
  isLoggedIn?: boolean;
}

interface StockItemObj {
  stockItem: StockItem;
  stockItemFormats: StockItemFormat[];
}

interface Features {
  redirectToSignUpLink?: boolean;
  isEnterpriseMember?: boolean;
  shouldShowAddToFavorites?: boolean;
  useRemainingSearchUI?: boolean;
  useSubscriptionlessSignUpEnticement?: boolean;
  isInVisitorVideoThumbnailExperimentVariantOne?: boolean;
  isInVisitorVideoThumbnailExperimentVariantTwo?: boolean;
}

const Drawer = ({
  customStyles = {},
  drawerType,
  indicatorClasses = null,
  selectedItemIndicatorOffset = null,
  close,
  drawerContentLoaded = false,
  features,
  dispatchUpdateDrawerNumberItemsVisible,
  itemLimit,
  stockItems = [],
  isLoggedIn,
}: Props) => {
  const useNewDrawerStyle =
    (features.isInVisitorVideoThumbnailExperimentVariantOne ||
      features.isInVisitorVideoThumbnailExperimentVariantTwo ||
      isLoggedIn) &&
    drawerType === DrawerType.MORE_LIKE_THIS;

  const isAltVersionsDrawer = () => {
    return drawerType === DrawerType.ALT_VERSIONS;
  };

  const getDrawerContentWrapperClasses = () => {
    const wrapperClasses = useNewDrawerStyle
      ? 'bg-gray-110 rounded-2xl p-6'
      : 'bg-gray-600 rounded-lg px-8 py-4';
    return twMerge(!isAltVersionsDrawer() && wrapperClasses);
  };

  const getDrawerContentClasses = () => {
    const drawerClasses = useNewDrawerStyle
      ? 'bg-gray-110'
      : 'px-8 py-4 bg-white rounded-lg';
    return twMerge(
      'content clear-both text-white relative',
      !isAltVersionsDrawer() && drawerClasses
    );
  };

  const Indicator = () => {
    if (!indicatorClasses && !selectedItemIndicatorOffset) {
      return null;
    }

    const indicatorStyles = {
      marginLeft: undefined,
      width: undefined,
    };
    if (selectedItemIndicatorOffset) {
      indicatorStyles.marginLeft = `${selectedItemIndicatorOffset}px`;
      indicatorStyles.width = '0';
    }

    return (
      <div className={indicatorClasses} style={indicatorStyles}>
        <div
          className={`${useNewDrawerStyle ? 'shape-new' : 'shape'} w-0 h-0`}
        />
      </div>
    );
  };

  const CloseButton = () => {
    const className = twMerge(
      'collapse-button text-4xl text-black absolute right-4 top-4 border-0 outline-none leading-4',
      isAltVersionsDrawer() && 'hidden'
    );

    if (useNewDrawerStyle) {
      return (
        <button
          aria-hidden="true"
          className="border-0 outline-none absolute right-0"
          onClick={close}
          title="Close"
        >
          <div className="bg-white w-11 h-11 rounded-full flex justify-center items-center">
            <Close className="w-4 h-4" />
          </div>
        </button>
      );
    }

    return (
      <button
        aria-hidden="true"
        className={className}
        onClick={close}
        title="Close"
      >
        &times;
      </button>
    );
  };

  const DrawerTitle = () => {
    const className = twMerge(
      'title m-0 text-left text-black text-base font-normal inline-block border-solid border-0 border-b border-gray-500',
      isAltVersionsDrawer() && 'hidden'
    );

    let titleText;
    switch (drawerType) {
      case DrawerType.MORE_LIKE_THIS:
        titleText = useNewDrawerStyle ? 'Similar Clips' : 'More Like This';
        break;
      case DrawerType.RECOMMENDED_MUSIC:
        titleText = 'Recommended Music';
        break;
      default:
        titleText = '';
    }

    if (useNewDrawerStyle) {
      return (
        <Button
          color={ButtonColor.Secondary}
          onClick={() => {
            return;
          }}
          className="bg-gray-800 py-3 px-4 border-0"
        >
          {titleText}
        </Button>
      );
    }
    return <h4 className={className}>{titleText}</h4>;
  };

  const StockItems = () => {
    const currentStockItems =
      stockItems.length && itemLimit
        ? stockItems.slice(0, itemLimit)
        : stockItems;

    if (drawerContentLoaded) {
      return (
        <SimpleSearchResults
          currentStockItems={currentStockItems}
          redirectToSignUpLink={features.redirectToSignUpLink}
          shouldShowAddToFavorites={features.shouldShowAddToFavorites}
          context={StockItemContext.DRAWER}
          containsCollapsedStockItems={true}
          updateDrawerNumberItemsVisible={
            dispatchUpdateDrawerNumberItemsVisible
          }
          useSubscriptionlessSignUpEnticement={
            features.useSubscriptionlessSignUpEnticement
          }
          useNewDrawerStyle={useNewDrawerStyle}
          searchResultsContainerCSSClasses={
            getCurrentSite() === VIDEOBLOCKS &&
            (features.isInVisitorVideoThumbnailExperimentVariantOne ||
              features.isInVisitorVideoThumbnailExperimentVariantTwo ||
              isLoggedIn)
              ? 'grid grid-cols-1 gap-3 md:grid-cols-3 lg:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6'
              : ''
          }
        />
      );
    }
    return <LoadingIndicator />;
  };

  return (
    <div
      className={twMerge(
        `searchDrawer w-full bg-transparent mb-2.5 px-2 py-0 col-span-1 md:col-span-2 lg:col-span-3 3xl:col-span-4 4xl:col-span-5`,
        (features.isInVisitorVideoThumbnailExperimentVariantOne ||
          features.isInVisitorVideoThumbnailExperimentVariantTwo ||
          isLoggedIn) &&
          'px-0'
      )}
      style={customStyles}
    >
      <Indicator />
      <div className={getDrawerContentWrapperClasses()}>
        <div className={getDrawerContentClasses()}>
          <CloseButton />
          <DrawerTitle />
          <StockItems />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    stockItems: selectCollapsedSetStockItems(state),
    drawerContentLoaded: selectDrawerContentLoaded(state),
    drawerType: selectDrawerType(state),
    features: selectSearchFeatures(state),
    isLoggedIn: selectIsLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(setPendingPauseAudio());
      stopRecommendedMusicDrawerTimer();
      dispatch(collapseMoreLikeThisDrawer());
    },
    dispatchUpdateDrawerNumberItemsVisible: (numItems) =>
      dispatch(updateDrawerNumberItemsVisible(numItems)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
