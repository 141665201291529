import { Tab } from '@headlessui/react';
import React, { Fragment, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { OriginArea } from '@videoblocks/events-ts/lib/storyblocks/search/OriginAreaEnum';
import { ChevronDown, ChevronUp, Close } from '@videoblocks/react-icons';

import VideoStockItemCard from '../../app/Search/components/card/VideoStockItemCard';
import useRelatedVideo, {
  RelatedVideoData,
  RelatedVideoDatum,
} from '../../app/Search/hooks/useRelatedVideo';
import { ContentType } from '../../app/Shared/enums';
import { StockItemContext } from '../types/StockItemTypes';
import LoadingIndicator from './LoadingIndicator';

export interface TabbedRelatedVideoProps {
  stockItemId: number;
  contentType: ContentType;
  gridColumnClassNames?: string;
  selectedTabButtonClassNames?: string;
  pageSize?: number;
  onClose?(): void;
}

export default function TabbedRelatedVideo({
  stockItemId,
  contentType,
  onClose,
  gridColumnClassNames = 'md:grid-cols-3 lg:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6',
  selectedTabButtonClassNames,
  pageSize,
}: TabbedRelatedVideoProps) {
  const { isInitialized, isErrored, data, loadMore } = useRelatedVideo(
    stockItemId,
    contentType,
    pageSize
  );

  const tabs = useMemo<
    {
      id: keyof RelatedVideoData;
      label: string;
      data: RelatedVideoDatum;
      clickOriginArea: OriginArea;
    }[]
  >(
    () =>
      [
        data?.sameShoot?.results?.length > 0
          ? {
              id: 'sameShoot' as keyof RelatedVideoData,
              label: 'Same Shoot',
              data: data.sameShoot,
              clickOriginArea: OriginArea.SAME_SHOOT,
            }
          : null,
        data?.sameModel?.results?.length > 0
          ? {
              id: 'sameModel' as keyof RelatedVideoData,
              label: 'Same Model',
              data: data.sameModel,
              clickOriginArea: OriginArea.SAME_MODEL,
            }
          : null,
        data?.similarItems?.results?.length > 0
          ? {
              id: 'similarItems' as keyof RelatedVideoData,
              label: 'Similar Clips',
              data: data.similarItems,
              clickOriginArea: OriginArea.SIMILAR_CLIPS,
            }
          : null,
      ].filter(Boolean),
    [data]
  );

  return (
    <div className="bg-transparent text-white relative flex-col">
      {/* Close button. */}
      {onClose && (
        <button
          aria-hidden="true"
          className="border-0 outline-none absolute right-0"
          onClick={onClose}
          title="Close"
        >
          <div className="bg-white w-11 h-11 rounded-full flex justify-center items-center">
            <Close className="w-4 h-4" />
          </div>
        </button>
      )}

      {isErrored ? (
        <div className="text-black text-center py-5">
          Whoops! Something went wrong. Please try again.
        </div>
      ) : isInitialized ? (
        <Tab.Group defaultIndex={0}>
          <Tab.List className="inline-flex space-x-1">
            {tabs.map((tab) => (
              <Tab as={Fragment} key={tab.label}>
                {({ selected }) => (
                  <button
                    className={twMerge(
                      selected
                        ? [
                            'text-white font-semibold bg-gray-800 hover:bg-gray-900 cursor-default',
                            selectedTabButtonClassNames,
                          ]
                        : 'text-gray-900 hover:bg-white',
                      'min-w-32 py-3 px-4 border-0 rounded-full transition-colors leading-6 text-center',
                      'focus:outline-none',
                      'hover:no-underline'
                    )}
                  >
                    {tab.label}
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {tabs.map((tab) => {
              // Cap the results at 48 items. After that do not allow loading any more.
              const canLoadMore =
                tab.data.hasMore && tab.data.results.length < 48;

              return (
                <Tab.Panel key={tab.label}>
                  <div
                    className={twMerge(
                      'stock-item-group-wrapper card-design grid grid-cols-1 gap-3 mt-5',
                      gridColumnClassNames
                    )}
                  >
                    {tab.data.results.map((stockItemDetails, index) => {
                      const { stockItem } = stockItemDetails;
                      return (
                        <VideoStockItemCard
                          key={stockItem.id}
                          stockItem={stockItem}
                          stockItemFormats={stockItemDetails.stockItemFormats}
                          shouldShowAddToFavorites
                          context={StockItemContext.DRAWER}
                          stockItemCardContext={{ position: index + 1 }}
                          shouldStockItemHaveNewFlag={
                            stockItem.shouldStockItemHaveNewFlag
                          }
                          shouldStockItemHaveStaffPickFlag={
                            stockItem.shouldStockItemHaveStaffPickFlag
                          }
                          shouldShowDownloadButton
                          topTags={stockItemDetails.topTags}
                          clickOriginArea={tab.clickOriginArea}
                          forceLoggedInStyling
                        />
                      );
                    })}
                  </div>
                  {/* View more button. */}
                  {canLoadMore && !tab.data.isLoading && (
                    <button
                      type="button"
                      className="border-none text-gray-1000 mx-auto mt-5 flex items-center"
                      onClick={() => loadMore(tab.id)}
                    >
                      View more
                      <ChevronDown className="w-3.5 h-3.6 ml-2 fill-current" />
                    </button>
                  )}

                  {/* Alternate close button. */}
                  {onClose && !canLoadMore && !tab.data.isLoading && (
                    <button
                      type="button"
                      className="border-none text-gray-1000 mx-auto mt-5 flex items-center"
                      onClick={onClose}
                    >
                      Collapse all
                      <ChevronUp className="w-3.5 h-3.6 ml-2 fill-current" />
                    </button>
                  )}

                  {/* Loading spinner. */}
                  {tab.data.isLoading && (
                    <div className="pt-2.5 -mb-2.5">
                      <LoadingIndicator compact />
                    </div>
                  )}
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
        </Tab.Group>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
}
